<template>
  <div id="address-form">
    <v-flex xs12>
      <h1 v-if="!isMobile"> {{ title }} </h1>

      <div :class="!isMobile ? 'item mt-5' : 'item'">
        <p class="text">
          <v-icon style="cursor:pointer;" v-if="!isMobile" @click="back()"> mdi-arrow-left </v-icon> {{ $t('address.subtitle') }}
        </p>

        <v-flex xs12 sm12 md12>
          <v-form
            ref="form"
            class="mt-9"
            v-model="valid"
            lazy-validation
          >
            <v-layout wrap>
              <v-flex xs12 sm5 md5>
                <v-text-field
                  v-model="name"
                  ref="name"
                  :label="$t('address.name')"
                  :rules="nameRules"
                  :outlined="isMobile"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm5 md5 offset-sm1 offset-md1>
                <v-text-field
                  v-model="address_name"
                  ref="address_name"
                  :label="$t('address.address-name')"
                  :rules="addressNameRules"
                  :outlined="isMobile"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm11 md11>
                <v-textarea
                  v-model="address"
                  ref="address"
                  :label="$t('address.address')"
                  :rules="addressRules"
                  :outlined="isMobile"
                  :rows="isMobile ? '5' : '1'"
                  required
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm5 md5>
                <v-combobox
                  v-model="province"
                  :items="provinceList"
                  :label="$t('address.province')"
                  :outlined="isMobile"
                  :rules="provinceRules"
                  @input="getCity()"
                ></v-combobox>
              </v-flex>
              <v-flex xs12 sm5 md5 offset-sm1 offset-md1>
                <v-combobox
                  v-model="city"
                  :items="cityList"
                  :label="$t('address.city')"
                  :outlined="isMobile"
                  :rules="cityRules"
                  @input="getDistrict()"
                ></v-combobox>
              </v-flex>
              <v-flex xs12 sm5 md5>
                <v-combobox
                  v-model="district"
                  :items="districtList"
                  item-text="subdistrict"
                  item-value="destination_code"
                  :label="$t('address.district')"
                  :outlined="isMobile"
                  :rules="districtRules"
                ></v-combobox>
              </v-flex>
              <v-flex xs12 sm5 md5 offset-sm1 offset-md1>
                <v-text-field
                  v-model="zip_code"
                  ref="zip_code"
                  :label="$t('address.zip-code')"
                  :rules="zipCodeRules"
                  :outlined="isMobile"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm5 md5>
                <v-text-field
                  v-model="phone"
                  ref="phone"
                  :label="$t('address.phone')"
                  :rules="phoneRules"
                  :outlined="isMobile"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm5 md5 offset-sm1 offset-md1>
                <v-combobox
                  v-model="priority"
                  :items="['YES', 'NO']"
                  :label="$t('address.primary-address')"
                  :outlined="isMobile"
                ></v-combobox>
              </v-flex>
            </v-layout>
            <v-flex xs12 sm5 md5 class="mb-9">
              <v-btn class="mt-5" tile :color="bgColor" dark width="100%" height="44px" @click="submit()">
                <span class="btn-text"> {{ title }} </span>
              </v-btn>
              <v-btn class="mt-1" outlined tile width="100%" height="44px" @click="back()">
                <span class="btn-text"> {{ $t('cancel')}} </span>
              </v-btn>
            </v-flex>
          </v-form>
        </v-flex>
      </div>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'AddressForm',
    props: {
      type: {
        type: String,
        default: 'create'
      },
      data: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    computed: {
      ...mapState([
        'isMobile',
        'userEmail',
        'token',
        'bgColor'
      ]),
      title () {
        if (this.type === 'create') {
          return this.$t('address.add-address')
        } else {
          return this.$t('address.edit-address')
        }
      }
    },
    data () {
      return {
        id_alamat: '',
        name:'',
        address_name: '',
        address: '',
        province: '',
        city: '',
        district: '',
        zip_code: '',
        phone: '',
        priority: 'YES',
        valid: true,
        nameRules: [
          v => !!v || this.$t('address.form.error-name')
        ],
        addressNameRules: [
          v => !!v || this.$t('address.form.error-address-name')
        ],
        addressRules: [
          v => !!v || this.$t('address.form.error-address')
        ],
        provinceRules: [
          v => !!v || this.$t('address.form.error-province')
        ],
        cityRules: [
          v => !!v || this.$t('address.form.error-city')
        ],
        districtRules: [
          v => !!v || this.$t('address.form.error-district')
        ],
        zipCodeRules: [
          v => !!v || this.$t('address.form.error-zip-code')
        ],
        phoneRules: [
          v => !!v || this.$t('address.form.error-phone')
        ],
        provinceList: [],
        cityList: [],
        districtList: [],
      }
    },
    methods: {
      back () {
        this.$emit('back')
      },
      initForm () {
        if (this.data && this.type !== 'create') {
          this.id_alamat = this.data.id_alamat
          this.name = this.data.penerima
          this.address_name = this.data.nama_alamat
          this.address = this.data.alamat_lengkap
          this.phone = this.data.hp_penerima
          this.zip_code = this.data.kode_pos
          this.priority = this.data.priority === 'primary' ? 'YES' : 'NO'
          this.province = this.data.province
          this.city = this.data.city
          this.district = {
            destination_code: this.data.destination_code,
            subdistrict: this.data.district
          }
          setTimeout(() => {
            this.getCity(true)
            this.getDistrict(true)
          }, 200);
        }
      },
      getProvince () {
        axios.get('/api/v1/master/province')
        .then(response => {
          this.provinceList = response.data.data.map(item => {
            return item.province
          })
        })
        .catch(error => {
          this.errorHandling(error)
        })
        // .finally(() => {
        //   this.$store.commit('setIsLoading', false)
        // })
      },
      getCity (isInit = false) {
        if (!isInit) {
          this.city = ''
        }

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          province: this.province,
          is_offline: true
        }

        axios.get('/api/v1/master/city')
        .then(response => {
          this.cityList = response.data.data.map(item => {
            return item.city
          })
        })
        .catch(error => {
          this.errorHandling(error)
        })
        // .finally(() => {
        //   this.$store.commit('setIsLoading', false)
        // })
      },
      getDistrict (isInit = false) {
        if (!isInit) {
          this.district = ''
        }
       
        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          city: this.city,
          is_offline: true
        }

        axios.get('/api/v1/master/subdistrict')
        .then(response => {
          this.districtList = response.data.data
        })
        .catch(error => {
          this.errorHandling(error)
        })
        // .finally(() => {
        //   this.$store.commit('setIsLoading', false)
        // })
      },
      submit () {
        if (this.$refs.form.validate()) {
          this.$store.commit('setIsLoading', true)

          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            token: this.token,
            is_offline: true
          }

          let data = {
            penerima: this.name.trim(),
            nama_alamat: this.address_name.trim(),
            hp_penerima: this.phone,
            kode_pos: this.zip_code,
            destination_code : this.district.destination_code,
            alamat_lengkap : this.address,
            priority: this.priority === 'YES' ? 'primary' : 'second'
          }

          if (this.type !== 'create') {
            axios.patch('/api/v1/member/alamat/' + this.id_alamat, data)
              .then(response => {
                this.$store.commit('setSnackbar', true)
                this.$store.commit('setText', response.data.message)

                this.$emit('submit')
              })
              .catch(error => {
                this.errorHandling(error)
              })
              .finally(() => {
                this.$store.commit('setIsLoading', false)
              })
          } else {
            axios.post('/api/v1/member/alamat', data)
              .then(response => {
                this.$store.commit('setSnackbar', true)
                this.$store.commit('setText', response.data.message)

                this.$emit('submit')
              })
              .catch(error => {
                this.errorHandling(error)
              })
              .finally(() => {
                this.$store.commit('setIsLoading', false)
              })
          }
        } 
      }
    }, 
    mounted () {
      this.initForm()
      this.getProvince()
    }
  }
</script>

<style scoped>
  .item {
    color: #000000;
  }

  .text {
    color: #000000;
    margin-bottom: 5px;
    font-size: 0.9rem !important;
  }
</style>

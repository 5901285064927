<template>
  <div id="order">
    <div v-if="!showForm">
      <v-flex xs12>
        <h1 v-if="!isMobile">{{ $t('address.title')}}</h1>
        <p v-if="!isMobile" class="text">
          {{ $t('address.subtitle2')}}
        </p>

        <v-divider v-if="!isMobile" class="mt-8 mb-4"></v-divider>

        <v-flex xs12 sm12 md12 v-if="address.length === 0">
          <p class="text"> {{ $t('address.no-data')}}  </p>
        </v-flex>

        <v-flex xs12 sm12 md12  :class="isMobile ? 'mt-8 mb-4' : ''"  v-for="(item, index) in address" :key="index">
          <v-layout wrap :class="isMobile ? 'item bordered' : 'pl-0'">
            <v-flex xs12 sm12 md12 class="pt-1">
              <div :class="isMobile ? 'address-btn mt-m30' : 'address-btn' ">
                <v-btn v-if="item.priority === 'primary'" small icon :color="bgColor" :title="$t('address.primary-address')">
                  <v-icon>mdi-check-bold</v-icon>
                </v-btn>
                <v-btn v-else small icon color="gray" @click="editPrimary(item)" title="set primary">
                  <v-icon>mdi-check-bold</v-icon>
                </v-btn>
              </div>
            </v-flex>
            <v-flex xs12 sm12 md8>
              <div class="detail-item">
                <span class="title"> {{ item.penerima }} </span> 
                <p class="subtitle mb-0">{{ item.alamat_lengkap }}</p>
                <p class="subtitle mb-0">{{ item.district }}</p>
                <p class="subtitle mb-0">{{ item.city }} {{ item.kode_pos }}</p>
                <p class="subtitle mb-0">{{ item.province }}</p>
                <p class="subtitle mb-0">Indonesia</p>
                <p class="subtitle">{{ item.hp_penerima }}</p>
              </div>
            </v-flex>
            <v-flex xs6 sm6 md2 class="pl-1 pr-1 pt-4">
              <v-btn tile :color="bgColor" width="100%" height="44px" elevation="1" @click="edit(item)">
                <span class="btn-text" style="color:#fff"> {{ $t('modification')}} </span>
              </v-btn>
            </v-flex>
            <v-flex xs6 sm6 md2 class="pl-1 pr-1 pt-4">
              <v-btn outlined tile color="black" width="100%" height="44px" elevation="1" @click="deleteAddress(item.id_alamat, index)">
                <span class="btn-text"> {{ $t('delete')}} </span>
              </v-btn>
            </v-flex>
          </v-layout>

          <v-divider v-if="!isMobile" class="mt-8"></v-divider>
        </v-flex>

        <v-flex xs12 sm12 md4 class="mt-5">
          <v-btn tile :color="bgColor" width="100%" height="44px" elevation="1" @click="create()">
            <span class="btn-text" style="color:#fff"> {{ $t('address.add-address')}} </span>
          </v-btn>
        </v-flex>
      </v-flex>
    </div>

    <Address-form v-else :type="type" :data="selectedData" @submit="reload()" @back="reload()" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
  import AddressForm from '@/components/AddressForm'

  export default {
    name: 'Address',
    components: { AddressForm },
    computed: {
      ...mapState([
        'isMobile',
        'token',
        'bgColor'
      ])
    },
    data () {
      return {
        address: [],
        showForm: false,
        type: '',
        selectedData : null
      }
    },
    methods: {
      edit (item) {
        this.selectedData = item
        this.showForm = true
        this.type = 'edit'
      },
      create () {
        this.showForm = true
        this.type = 'create'
      },
      reload () {
        this.showForm = false
        this.selectedData = null
        this.getAddress()
      },
      editPrimary (item) {
        this.$store.commit('setIsLoading', true)
        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token,
          is_offline: true
        }

        axios.patch('/api/v1/member/alamat_priority/' + item.id_alamat)
          .then(response => {
            this.$store.commit('setSnackbar', true)
            this.$store.commit('setText', response.data.message)

            this.getAddress()
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      getAddress () {
        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token,
          is_offline: true
        }

        axios.get('/api/v1/member/alamat')
          .then(response => {
            this.address = response.data.data
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      deleteAddress (id,index) {
        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token,
          is_offline: true
        }

        axios.delete('/api/v1/member/alamat/'+ id)
          .then(response => {
            this.$store.commit('setSnackbar', true)
            this.$store.commit('setText', response.data.message)
            this.address.splice(index, 1)
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      }
    }, 
    mounted () {
      this.setMetaInfo(null, 'Address Book')
      this.getAddress()
      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setIsMemberPage', true)
      this.$store.commit('setActivePage', this.$t('address.title'))
    }
  }
</script>

<style scoped>
  .item {
    padding: 10px;
  }

  .detail-item {
    color: #000000;
  }

  .text {
    color: #000000;
    margin-bottom: 5px;
    font-size: 0.9rem !important;
    font-style: normal !important;
  }

  .title {
    color: #000000;
    font-weight: 900;
    font-size: 0.9rem !important;
  }

  .subtitle {
    color: #000000;
    font-size: 0.8rem !important;
  }

  .subtitle-gray {
    color: #808080;
    font-size: 0.8rem !important;
  }

  .bordered {
    border: 1px solid #000000;
  }

  .btn-text {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .address-btn {
    float: right;
  }

  .mt-m30 {
    margin-bottom: -30px;
  }

</style>
